<template>
  <div
    class="bg-gray-900 text-white min-h-screen"
    style="
      background-image: url('https://wallpapercave.com/wp/GYYs5kF.jpg');
      background-size: cover;
      background-position: center;
    "
  >
    <nav class="bg-gray-800 p-4 shadow-lg">
      <div class="max-w-7xl mx-auto flex justify-between items-center">
        <div class="text-lg font-bold">DK Studio</div>
        <div class="space-x-4 flex">
          <router-link
            to="/"
            class="hover:text-blue-500 transition duration-300"
            >Home</router-link
          >
          <router-link
            to="/store"
            class="hover:text-blue-500 transition duration-300"
            >Store</router-link
          >
          <router-link
            to="/projects"
            class="hover:text-blue-500 transition duration-300"
            >Projects</router-link
          >
          <router-link
            to="/contact"
            class="hover:text-blue-500 transition duration-300"
            >Contact</router-link
          >
          <router-link
            to="/about"
            class="hover:text-blue-500 transition duration-300"
            >About</router-link
          >
        </div>
      </div>
    </nav>
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
});
</script>

<style></style>
