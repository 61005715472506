<template>
  <div class="min-h-screen flex flex-col items-center justify-center py-10">
    <div
      class="bg-gray-800 p-10 rounded-lg shadow-lg text-center max-w-l mx-auto"
    >
      <h1 class="text-5xl font-bold mb-4">Welcome to Dank Harmony's Studio</h1>
      <h2 class="text-3xl mb-4">Let's do some music!</h2>
      <img
        src="@/assets/profile.jpg"
        alt="Profile Picture"
        class="w-40 h-40 rounded-full border-4 border-blue-500 mb-4 shadow-md mx-auto"
      />
      <p class="text-lg mb-4">
        Some genres that I produce:
        <span class="text-blue-500"
          >Hip-Hop, Reggae, BoomBap, Trap, Reggaeton and Dancehall</span
        >
      </p>
      <div class="mt-6 flex justify-center space-x-4">
        <a
          href="#"
          class="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 transition duration-300 flex items-center space-x-2"
        >
          <i class="fab fa-beatstars fa-lg"></i> <span>BeatStars</span>
        </a>
        <a
          href="#"
          class="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 transition duration-300 flex items-center space-x-2"
        >
          <i class="fab fa-youtube fa-lg"></i> <span>Youtube</span>
        </a>
        <a
          href="#"
          class="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 transition duration-300 flex items-center space-x-2"
        >
          <i class="fab fa-spotify fa-lg"></i> <span>Spotify</span>
        </a>
        <a
          href="#"
          class="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 transition duration-300 flex items-center space-x-2"
        >
          <i class="fab fa-instagram fa-lg"></i> <span>Instagram</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomePage",
});
</script>

<style scoped></style>
